/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import React from 'react';
import {CustomTheme, Sizing, useTheme} from '@vogue/theme/src';

export interface IconProps {
    marginLeft?: Sizing
}

const styles = (theme: CustomTheme, props: IconProps) => css({
    width: '28px',
    verticalAlign: 'middle',
    lineHeight: '1px',
    marginLeft: props.marginLeft ? theme.spacing(props.marginLeft, 'horizontal') : 0,
});

export const Icon: React.FC<IconProps> = (props) => {
    const theme = useTheme();

    return (
        <svg css={styles(theme, props)} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
        </svg>
    );
}
