import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {VogueThemeProvider, Global} from '@vogue/theme';
import {Header, Info, Spinner} from '@vogue/display';

const HomePage = React.lazy(() => import('./screen/HomePage'));

export const App = () => {

    return (
        <VogueThemeProvider>
            <BrowserRouter>
                <Global />
                <Info>This site is under construction and should not be used</Info>
                <Header>
                    <Header.Brand>Keystone Crusade</Header.Brand>
                </Header>
                <React.Suspense fallback={<Spinner/>}>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </VogueThemeProvider>
    );
}
