import { palette } from './palette';
import { typography } from './typography';
import { spacing } from './spacing';
import {fonts} from "./fonts";
import {CustomTheme} from '../interfaces';

export const themeConfig: CustomTheme = {
    palette,
    typography,
    spacing,
    fonts,
}
