import React from 'react';

export const Spinner: React.FC = () => {
    return (
        <div>
            Loading...
        </div>
    );
}

export default Spinner;
