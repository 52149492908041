import React from "react";
import styled from "@emotion/styled";
import {CustomTheme, useTheme} from '@vogue/theme';

export interface InfoProps {
    variant?: keyof CustomTheme['palette'];
}

export const Info: React.FC = styled.div<InfoProps>((props) => {
    const theme = useTheme();

    return {
        backgroundColor: theme.palette[props.variant || 'primary'].main,
        color: 'white',
        padding: theme.spacing('md'),
    }
});
