import styled from "@emotion/styled";
import { useTheme } from "@vogue/theme";

export interface TypographyProps {
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const H1 = styled.h1(() => {
    const theme = useTheme()

    return {
        fontFamily: theme.fonts.families.pageHeading,
        fontWeight: 100,
    }
});

export const H2 = styled.h1(() => {
    const theme = useTheme()

    return {
        fontFamily: theme.fonts.families.heading,
        fontWeight: 500,
    }
});

export const H3 = styled.h1(() => {
    const theme = useTheme()

    return {
        fontFamily: theme.fonts.families.heading,
        fontWeight: 500,
    }
});
