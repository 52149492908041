/** @jsx jsx */
import { jsx } from '@emotion/react'

import React from 'react';
import {CSSInterpolation} from '@emotion/serialize';

interface RadioProps {
    label: string;
    groupName?: string;
}

export interface RadioGroupProps {
    groupName?: string;
    radios: Array<RadioProps>;
}

const commonCss: CSSInterpolation = {
    display: 'block',
    padding: '5px 0px',
    input: {
        display: 'none'
    },
    div: {
        ':before': {
            content: '""',
            display: 'block',
            height: '10px',
            width: '10px',
            float: 'left',
            outline: '#ccc 3px solid',
            border: '#fff 3px solid',
            borderRadius: '10px',
            marginRight: '10px',
            background: '#fff',
        }
    },
    'input:checked + div:before': {
        background: '#ccc',
    }
};

const Radio: React.FC<RadioProps> = (props) => {
    const id: string = Math.random().toString();
    return (
        <label css={commonCss} htmlFor={id}>
            <input type="radio" name={props.groupName} id={id}/>
            <div>{props.label}</div>
        </label>
    );
}

const processList = (radioProps: RadioProps, index: number): React.ReactElement => {
    return <Radio key={index} label={radioProps.label} groupName={radioProps.groupName} />;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
    return (
        <div>
            { props.radios.map((radioProps, index) => {
                return processList({groupName: props.groupName, ...radioProps}, index);
            })}
        </div>
    );
}

export default RadioGroup;
