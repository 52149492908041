/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import React from 'react';

import { useTheme, CustomTheme } from '@vogue/theme';
import {Button} from '@vogue/inputs';
import {H1} from '../Text';

const CardTitle: React.FC = (props) => {
    const theme = useTheme();
    const styles = css({
        margin: theme.spacing('md'),
        marginBottom: 0,
    });

    return <H1 css={styles}>{props.children}</H1>;
}

const CardContent: React.FC = (props) => {
    const theme = useTheme();
    const styles = css({
        margin: theme.spacing('md'),
        flexGrow: 1,
    });

    return <div css={styles}>{props.children}</div>;
}
const CardAction = Button;

export interface CardProps {
    variant?: 'primary' | 'secondary' | 'default',
}
export interface CardComponentInterface extends React.FC<CardProps> {
    Title: typeof CardTitle,
    Content: typeof CardContent,
    Action: typeof CardAction,
}

const stylingFuncs = {
    'default': () => {
        return css({
            backgroundColor: '#fafafa',
        });
    },
    'primary': (theme: CustomTheme) => {
        return css({
            backgroundColor: theme.palette.primary.main,
            '&>*': {
                color: 'white',
            }
        });
    },
    'secondary': (theme: CustomTheme) => {
        return css({
            backgroundColor: theme.palette.secondary.main,
            '&>*': {
                color: 'white',
            }
        });
    }
};

export const Card: CardComponentInterface = (props) => {

    const theme = useTheme();
    const styles = css({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    });
    const variant = stylingFuncs[props.variant || 'default'](theme);

    return (
        <div css={[styles, variant]}>{props.children}</div>
    );
}

Card.Title = CardTitle;
Card.Content = CardContent;
Card.Action = CardAction;
