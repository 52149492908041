import {CustomTheme} from '../interfaces';

const baseSizeRem = 1;
const scale = 1.25;

export const typography: CustomTheme['typography'] = {
    h6: `${baseSizeRem}rem`,
    h5: `${baseSizeRem * scale}rem`,
    h4: `${baseSizeRem * scale * scale}rem`,
    h3: `${baseSizeRem * scale * scale * scale}rem`,
    h2: `${baseSizeRem * scale * scale * scale * scale}rem`,
    h1: `${baseSizeRem * scale * scale * scale * scale * scale}rem`,

}
