import {CustomTheme, Sizing} from '../interfaces';

const scale = 1.25;

const horizontalBasePx = 22;
const horizontal: Map<Sizing, number> = new Map([
    ['xs', horizontalBasePx / scale / scale],
    ['sm', horizontalBasePx / scale],
    ['md', horizontalBasePx],
    ['lg', horizontalBasePx * scale],
]);

const verticalBasePx = 16;
const vertical: Map<Sizing, number> = new Map([
    ['xs', verticalBasePx / scale / scale],
    ['sm', verticalBasePx / scale],
    ['md', verticalBasePx],
    ['lg', verticalBasePx * scale],
]);

export const spacing: CustomTheme['spacing'] = (coefficient, singleton ) => {
    const hor: number = horizontal.get(coefficient) || horizontalBasePx;
    const ver: number = vertical.get(coefficient) || verticalBasePx;

    if(singleton === 'vertical') {
        return `${ver}px`;
    }
    if (singleton === 'horizontal') {
        return `${hor}px`;
    }

    return `${ver}px ${hor}px`;
};
