/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import React from "react";

import { useTheme } from '@vogue/theme';

import {Brand} from "./Brand";

export interface HeaderFunctional extends React.FC {
    Brand: React.FC;
}

export const Header: HeaderFunctional = (props) => {

    const theme = useTheme();
    const styling = css({
        padding: theme.spacing('md'),
        borderBottom: `${theme.palette.primary.main} 1px solid`,
        display: "flex",
        '&>*': {
            flex: '1 1 auto',
        }
    });

    return (
        <div css={styling}>{props.children}</div>
    );
};

Header.Brand = Brand;
