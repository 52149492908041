import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from "firebase/app";
import { initializeAnalytics, getAnalytics } from "firebase/analytics";

import { App } from './App';
import {config} from './config';

const app = initializeApp(config.firebase);
initializeAnalytics(app);
getAnalytics(app);

ReactDOM.render(<App />, document.getElementById('root'));
