import {FontFamilies, Fonts} from '../interfaces';

const fontFamilies: FontFamilies = {
    pageHeading: "'Fira Sans', sans-serif",
    heading: "'Montserrat', sans-serif",
    body: "'Montserrat', sans-serif",
    fileImports: [
        'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100&display=swap',
        'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap',
    ],
};

export const fonts: Fonts = {
    families: fontFamilies,
}
